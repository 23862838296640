<template>

<b-container v-if="this.user" class="py-4">
  <b-form @submit="onSubmit">
    <b-form-group id="input-group-username" :label="t('username')" label-for="input-username">
      <b-form-input id="input-username" v-model="user.username" required></b-form-input>
    </b-form-group>
    <b-form-group id="input-group-email" :label="t('email')" label-for="input-email">
      <b-form-input id="input-email" v-model="user.email" required></b-form-input>
    </b-form-group>
    <b-button type="submit" variant="success">{{ t('save') }}</b-button>
    <b-button @click="logout">{{ t('logout') }}</b-button>
  </b-form>
</b-container>

</template>

<script>

export default {
  data: function() {
    return {
      user: undefined,
    }
  },
  methods: {
    t: function(s) {
      return this.$store.getters.t(s)
    },
    get: function() {
      this.$http.get('/users').then(response => {
        this.user = response.data
      }).catch(error => {
        this.$store.dispatch('handleError', error.response)
      });
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.updateUser()
    },
    updateUser: function() {
      this.$http.patch('/users', this.user).then((response) => {
        this.$store.commit('setUser', response.data)
        this.$store.dispatch('updateNotif', this.t('user_updated'))
      }).catch(error => {
        this.$store.dispatch('handleError', error.response)
      });
    },
    logout: function() {
      this.$store.commit('logout')
      window.location.reload()
    },
  },
  created () {
    this.get()
  }
}
</script>

<style scoped lang="scss">

</style>
