<template>

<b-container class="py-5">
  <b-tabs>
    <b-tab :title="t('my_account')" active>
      <Profile/>
    </b-tab>
    <b-tab :title="t('password')">
      <Password/>
    </b-tab>
  </b-tabs>
</b-container>

</template>

<script>

import Profile from '@/components/profile/Profile.vue'
import Password from '@/components/profile/Password.vue'

export default {
  components: {
    Profile,
    Password,
  },
  methods: {
    t: function(s) {
      return this.$store.getters.t(s)
    },
  }
}
</script>

<style scoped lang="scss">

</style>
