<template>

<b-container class="py-4">
  <b-form @submit="onSubmit">
    <b-form-group id="input-group-password" :label="t('password')" label-for="input-password">
      <b-form-input id="input-password" type="password" v-model="password" required></b-form-input>
    </b-form-group>
    <b-form-group id="input-group-confirmation" :label="t('confirmation')" label-for="input-confirmation">
      <b-form-input id="input-confirmation" type="password" v-model="confirmation" required></b-form-input>
    </b-form-group>
    <b-button type="submit" variant="success">{{ t('save') }}</b-button>
  </b-form>
</b-container>

</template>

<script>

export default {
  data: function() {
    return {
      password: '',
      confirmation: '',
    }
  },
  methods: {
    t: function(s) {
      return this.$store.getters.t(s)
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.updatePassword()
    },
    updatePassword: function() {
      this.$http.patch('/users/password', {
        password: this.password,
        confirmation: this.confirmation,
      }).then(
        this.$store.dispatch('updateNotif', this.t('password_updated'))
      ).catch(error => {
        this.$store.dispatch('handleError', error.response)
      })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
